import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import WishModal from "../../components/WishModal";
import { AppUtils } from "../../services/AppUtils";
import Data from "../../data/Data.json";

const videos = Data.videotestdata;
const languages = Data.languages;
const breadcrumbs = [
  { title: "Home", linkTo: "/" },
  { title: "E-learning", linkTo: "/e-learning" },
];

export default function Elearning() {
  const [selectedLanguage, setSelectedLanguage] = useState(1); // Default language ID
  const [loading, setLoading] = useState(true); // Loader state

  useEffect(() => {
    // Simulate data loading
    const timeout = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timeout); // Cleanup timeout
  }, []);

  useEffect(() => {
    console.log(selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageChange = (languageId) => {
    setSelectedLanguage(languageId); // Update selected language
    AppUtils.hideDialog("dlgSelectLanguage"); // Close modal
  };

  return (
    <PageLayout activeSideMenu="9" pageTitle="E-Learning" header="E-Learning" breadcrumbs={breadcrumbs}>
      <button className="btn btn-sm btn-link mb-1 p-0" onClick={() => AppUtils.showDialog("dlgSelectLanguage")}>
        Change Language
      </button>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {loading
          ? Array.from({ length: 6 }).map((_, idx) => (
              <div key={idx} className="card video-card skeleton-card" style={{ width: "18rem", height: "16rem" }}>
                <div className="skeleton-thumbnail" style={{ height: "10rem", background: "#e0e0e0" }} />
                <div className="skeleton-body" style={{ padding: "10px" }}>
                  <div className="skeleton-title" style={{ height: "1.5rem", background: "#e0e0e0", marginBottom: "5px" }} />
                  <div className="skeleton-text" style={{ height: "1rem", background: "#e0e0e0", width: "60%" }} />
                </div>
              </div>
            ))
          : videos.map((video) => {
              const Wrapper = video.locked ? "div" : Link;
              const wrapperProps = video.locked
                ? {
                    style: {
                      cursor: "not-allowed",
                      pointerEvents: "none",
                      textDecoration: "none",
                    },
                  }
                : {
                    to: "/e-learning-video",
                    state: { video },
                    style: { textDecoration: "none" },
                  };

              return (
                <Wrapper key={video.id} {...wrapperProps}>
                  <div
                    className="card video-card"
                    style={{
                      opacity: video.locked ? 0.6 : 1,
                      transition: "transform 0.3s, boxShadow 0.3s",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <img src={video.thumbnail} alt={video.title} className="card-img-top" style={{ width: "100%", height: "auto" }} />
                      {!video.locked && (
                        <div className="video-tile-overlay">
                          <span className="video-play-icon" style={{ fontSize: "48px", color: "white" }}>
                            ▶
                          </span>
                        </div>
                      )}
                      {video.locked && (
                        <div className="video-tile-overlay" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                          <span style={{ fontSize: "48px", color: "white" }}>🔒</span>
                        </div>
                      )}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{video.title}</h5>
                      <p className="card-text text-small text-secondary">{video.description}</p>
                    </div>
                  </div>
                </Wrapper>
              );
            })}
      </div>

      <WishModal
        id={"dlgSelectLanguage"}
        noFooter={true}
        hideCancelButton={true}
        infoMode={
          <div className="my-3">
            <h4 className="text-center">Welcome to E-learning</h4>
            <p className="text-center">Please select your language</p>
            <div className="language-buttons">
              {languages.map((language) => (
                <button key={language.value} className="btn btn-sm btn-outline-primary" onClick={() => handleLanguageChange(language.value)}>
                  {language.label}
                </button>
              ))}
            </div>
          </div>
        }
      />
    </PageLayout>
  );
}
