import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import useAPIs from "./useAPIs";

export const authenticationModel = {
  user_name: Number,
  user_type: (Number, 1),
  password: String,
  device_id: (String, ""),
  device_info: (String, ""),
  device_token: (String, ""),
  mplatform_id: (Number, 1),
  location: (String, ""),
};

export const forgotPasswordModel = {
  user_name: (Number, -1),
  user_type: (Number, -1),
};

const useAuthentication = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  //const [loading, setLoading] = useState(false);
  const { apiError, processing, postData } = useAPIs();

  useEffect(() => {
    if (response) {
      //setLoading(false);
    }
  }, [response]);

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  const login = (credentials = {}, onSuccess) => {
    const userCredentials = _.cloneDeep(authenticationModel);
    userCredentials.user_name = credentials.user_name;
    userCredentials.password = credentials.password;

    //setLoading(true);
    postData(
      "/auth/login",
      userCredentials,
      (data) => {
        setResponse(data);
        onSuccess && onSuccess(data);
      }
      //setError
    );
  };

  const forgotPassword = (changePasswordDetails, onSuccess) => {
    //setLoading(true);
    postData(
      "/auth/send-password-reset-otp",
      changePasswordDetails,
      (data) => {
        setResponse(data);
        onSuccess && onSuccess(data);
      }
      //setError
    );
  };

  const verifyPasswordReset = (payload, onSuccess, onError) => {
    //setLoading(true);
    postData(
      "/auth/verify-password-reset-otp",
      payload,
      (data) => {
        setResponse(data);
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
      //setError
    );
  };

  const logout = (credentials = authenticationModel, onSuccess, onFailure) => {
    const userCredentials = _.cloneDeep(authenticationModel);
    userCredentials.user_name = credentials.user_name;
    userCredentials.isReadyToAuthenticate = credentials.isReadyToAuthenticate;

    //setLoading(true);
    postData(
      "/auth/logout",
      userCredentials,
      (data) => {
        setResponse(data);
        localStorage.clear();
        onSuccess && onSuccess(data);
      },
      (error) => {
        onFailure && onFailure(error);
      }
      //setError
    );
  };

  const changePassword = (payload, onSuccess) => {
    //setLoading(true);
    postData(
      "/auth/change-user-password",
      payload,
      (data) => {
        setResponse(data);
        onSuccess && onSuccess(data);
      }
      //setError
    );
  };

  const fetchDeleteReasons = (onSuccess) => {
    //setLoading(true);
    postData("/enrollment/fetch-delete-reason", {}, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const submitDeleteRequest = (payload, onSuccess, onError) => {
    //setLoading(true);
    postData(
      "/enrollment/id-delete-request",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const sendOTPDelete = (payload, onSuccess, onError) => {
    postData(
      "/enrollment/send-otp-delete",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const verifyOTPDelete = (payload, onSuccess, onError) => {
    postData(
      "/enrollment/verify-otp-delete",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  return {
    error,
    response,
    login,
    forgotPassword,
    verifyPasswordReset,
    logout,
    processing,
    changePassword,
    fetchDeleteReasons,
    submitDeleteRequest,
    sendOTPDelete,
    verifyOTPDelete,
  };
};

export default useAuthentication;
