import React, { useState } from "react";
import { AppUtils } from "../services/AppUtils";
import useEnrollment from "../services/useEnrollment";
import WishModal from "./WishModal";

export default function Feedback() {
  const { postFeedback } = useEnrollment();
  const [payload, setPayload] = useState({ score: null, feedback: "", page_url: "" });
  const [errors, setErrors] = useState({ score: null, feedback: null });

  const submitFeedback = (e) => {
    if (!payload.score) {
      setErrors({ ...errors, score: "Please select a score" });
      return;
    }
    if (!payload.feedback) {
      setErrors({ ...errors, feedback: "Please write a feedback" });
      return;
    }
    console.log(payload);
    postFeedback(
      payload,
      (data) => {
        setPayload({ score: null, feedback: "", page_url: "" });
        AppUtils.hideDialog("dlgFeedback");
      },
      (error) => {}
    );
  };

  return (
    <>
      <button
        className="btn btn-sm btn-primary feedback-button"
        onClick={(e) => {
          AppUtils.showDialog("dlgFeedback");
        }}
      >
        Feedback
      </button>
      <WishModal id="dlgFeedback" title={"Give us your Feedback"} onFinish={submitFeedback} autoDismissOnFinish={false}>
        <p>Your survey responses are collected by Indusviva Healthsciences for analysis, to better serve you. </p>
        <p>
          Based on your experience at Indusviva Virtual Office, how likely are you to recommend us to your friends or colleagues?<span className="text-danger">*</span>
        </p>
        <div style={{ marginBottom: "1rem" }}>
          <p>
            <strong>0</strong> = Not at all likely, <strong>10</strong> = Extremely likely
          </p>
          {/* Button group for scores from 0 to 10 */}
          <div className="btn-group w-100" role="group" aria-label="Feedback Rating">
            {Array.from({ length: 11 }, (_, i) => (
              <button
                key={i}
                type="button"
                className={`btn btn-sm btn-outline-primary feedback-rank-btn ${payload.score === i ? "active" : ""}`}
                onClick={() => {
                  setPayload({ ...payload, score: i, page_url: window.location.href });
                  setErrors({ ...errors, score: null });
                }}
              >
                {i}
              </button>
            ))}
          </div>
          <p className="text-danger text-small">{errors.score}</p>
        </div>
        <p>
          Please let us know why you gave us this score and how we can improve your experience.<span className="text-danger">*</span>
        </p>
        <textarea
          className="form-control"
          rows={5}
          maxLength={500}
          placeholder="Maximum 500 characters"
          value={payload.feedback}
          onChange={(e) => {
            setPayload({ ...payload, feedback: e.target.value, page_url: window.location.href });
            setErrors({ ...errors, feedback: null });
          }}
        />
        <p className="text-danger text-small">{errors.feedback}</p>
      </WishModal>
    </>
  );
}
