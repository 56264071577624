import React from "react";
import WishSimpleCard from "../../components/WishSimpleCard";
import EmptyNote from "../../components/EmptyNote";
import WishFlexBox from "../../components/WishFlexBox";
import data from "../../data/Data.json";
import NumberCounter from "../../components/NumberCounter";
import SkeletonLoader from "../../components/SkeletonLoader";

export default function GroupVolume({ distributorGVStats, selectedDistributor, loading }) {
  const distributor = JSON.parse(localStorage.getItem("distributor"));
  const RenderGroupVolumeTile = ({ details, addTopPadding = false }) => {
    return (
      <div>
        <div className="py-1 rounded-lg onhover-shadow">
          <WishFlexBox>
            <label className="text-primary m-0">{details.title} Organization</label>
            <div className="d-flex">
              <span className="lead d-flex align-items-center">
                <NumberCounter targetValue={details.PV} />
                {details.subTitle}
              </span>
              <span className={"lead " + (details.direction === "up" ? "text-success" : "text-danger")}>
                <i className={"las " + (details.direction === "up" ? "la-long-arrow-alt-up text-success" : "la-long-arrow-alt-down text-danger")}></i>{" "}
                <NumberCounter targetValue={details.percentage} />%
              </span>
            </div>
          </WishFlexBox>
        </div>
      </div>
    );
  };

  return (
    <WishSimpleCard className="rounded-1 border-light" header={<h5>Group Volume</h5>} changeBorder={false} cardBodyClassName="flex-none overflow-auto">
      {loading ? (
        <SkeletonLoader rows={6} />
      ) : (
        <div className="" style={{ minHeight: "400px" }}>
          {distributor.distributor_id === selectedDistributor
            ? distributorGVStats?.map((info, index) => {
                const GVTileInfo = {
                  title: data.organizations[index],
                  subTitle: "GV",
                  direction: info.is_increase ? "up" : "down",
                  PV: info.cw_gv ?? 0,
                  percentage: info.percentage ?? 0,
                };

                return <RenderGroupVolumeTile details={GVTileInfo} addTopPadding={index !== 0} key={index} />;
              })
            : distributorGVStats?.slice(0, 2).map((info, index) => {
                const GVTileInfo = {
                  title: data.organizations[index],
                  subTitle: "GV",
                  direction: info.is_increase ? "up" : "down",
                  PV: info.cw_gv ?? 0,
                  percentage: info.percentage ?? 0,
                };

                return <RenderGroupVolumeTile details={GVTileInfo} addTopPadding={index !== 0} key={index} />;
              })}
        </div>
      )}
    </WishSimpleCard>
  );
}
