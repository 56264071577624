import React, { useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import WishCard from "../../components/WishCard";
import EmptyAnimation from "../../components/EmptyAnimation";
import animation from "../../assets/app-assets/images/lottie/NoDataAvailable.json";

const breadcrumbs = [
  { title: "Home", linkTo: "/" },
  { title: "Reports & Trends", linkTo: "/reportsandtrends" },
];

const Enrollment = () => {
  const distributor = JSON.parse(localStorage.getItem("distributor"));

  const studentAccess = JSON.parse(localStorage.getItem("student_access"));

  return (
    <PageLayout activeSideMenu="3" pageTitle="Enrollments" header="OPTIONS" breadcrumbs={breadcrumbs}>
      {distributor?.distributor_rank?.rank_id > 1 || studentAccess?.comment !== 0 ? (
        <section className="row">
          <div className="col-md-4 col-sm-6">
            <WishCard
              bgColor="bg-primary"
              textWhite={true}
              title="Enroll a New Viva Retailer"
              icon="la-user-plus"
              maxHeight={true}
              line1="Click & fill in the necessary details to enroll new VR into your network. "
              linkTo="/enrolluser"
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <WishCard
              title="Incomplete Profiles "
              icon="la-user-friends"
              maxHeight={true}
              line1="View and edit incomplete profiles for enrollment. "
              linkTo="/ongoingenrollments"
            />
          </div>

          <div className="col-md-4 col-sm-6">
            <WishCard
              title="Generate Link"
              icon="la-link"
              line1="Generate and copy the enrollment link from here and share it with your prospects."
              line2={["You can also share QR code for mobile based enrollment."]}
              linkTo="/generatelink"
              maxHeight={true}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <WishCard
              title="Personal Enrollment Report "
              icon="la-user-friends"
              maxHeight={true}
              line1="Get detailed information about the distributors you have Sponsored. "
              linkTo="/personalenrollmentreport"
            />
          </div>
          {/* <div className="col-md-4 col-sm-6">
            <WishCard
              title="Update placement report "
              icon="la-user-friends"
              maxHeight={true}
              line1="Change the placement of the distributors who have bee enrolled most recently "
              linkTo="/newenrollmentreport"
            />
          </div> */}
          {/* <div className="col-md-4 col-sm-6">
            <WishCard
              title="Inactive IDs without First Order Report "
              icon="la-user-friends"
              maxHeight={true}
              line1="View and delete inactive distributors without first order that you have Sponsored. "
              linkTo="/inactiveidswoforeport"
            />
          </div> */}

          {/* Additional div element with no content */}
          <div className="col-md-4 col-sm-6"></div>
        </section>
      ) : (
        <div className="col-12">
          <EmptyAnimation message={"No options to display. Please come back when you're a star distributor or above"} lottieAnimation={animation} />
        </div>
      )}
    </PageLayout>
  );
};

export default Enrollment;
