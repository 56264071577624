// routes.js
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useLocalStorage from "react-use-localstorage/dist";
import WishToaster from "./components/WishToaster";

import Dashboard from "./views/Dashboard";
import SignIn from "./views/authentication/signin";
import Sales from "./views/sales/Sales";
import Enrollment from "./views/enrollment/Enrollment";
import MyGeneology from "./views/Genealogy/MyGeneology";
import Announcements from "./views/Announcements";
import Analysis from "./views/analysis/Analysis";
import VmailNew from "./views/vmail/Vmail";
import Elearning from "./views/Elearning/Elearning";
import ReportsDash from "./views/Reports/ReportsDash";
import CreditNote from "./views/CreditNote";
import { EnrollmentProvider } from "./contexts/EnrollUserContext";

const StorelinkOrders = React.lazy(() => import("./views/storelinkorders/StorelinkOrders"));
const Error404 = React.lazy(() => import("./views/Error404"));
const ReportGeneral = React.lazy(() => import("./views/Reports/ReportGeneral"));
const VolumeReport = React.lazy(() => import("./views/Reports/VolumeReport"));
const InactiveIdWithoutFirstOrderReport = React.lazy(() => import("./views/Reports/InactiveIdWoFoReport"));
const ReportGeneralComponent = React.lazy(() => import("./views/Reports/ReportGeneralComponent"));
const VotmInventory = React.lazy(() => import("./views/sales/votminventory/VotmInventory"));
const VOTMDetailsPage = React.lazy(() => import("./views/sales/votminventory/VotmDetailsPage"));
const VOTMConsolidatedOrderDetails = React.lazy(() => import("./views/sales/votminventory/VotmConsolidatedDetails"));
const PlaceOrder = React.lazy(() => import("./views/sales/PlaceOrder"));
const CongratulationsPage = React.lazy(() => import("./views/CongratulationsPage"));
const FailurePage = React.lazy(() => import("./views/FailurePage"));
const CommissionReports = React.lazy(() => import("./views/Reports/CommissionReports"));
const DeductionsAndAdjustments = React.lazy(() => import("./views/Reports/DeductionsAndAdjustments"));
const CoApplicantBonusReport = React.lazy(() => import("./views/Reports/CoApplicantBonusReport"));
const NewDistributorReports = React.lazy(() => import("./views/Reports/DistributorReports"));
const SelectFromGeneology = React.lazy(() => import("./views/Genealogy/SelectFromGenealogy"));
const ProfileDashboard = React.lazy(() => import("./views/personal/ProfileDashboard"));
const MyOrders = React.lazy(() => import("./views/sales/MyOrders"));
const EnrollUser = React.lazy(() => import("./views/enrollment/EnrollUser"));
const OngoingEnrollments = React.lazy(() => import("./views/enrollment/OngoingEnrollments"));
const GenerateLink = React.lazy(() => import("./views/enrollment/GenerateLink"));
const OrderDetails = React.lazy(() => import("./views/sales/OrderDetails"));
const GenealogySettings = React.lazy(() => import("./views/Genealogy/GenealogySettings"));
const Notifications = React.lazy(() => import("./views/Notifications"));
const Settings = React.lazy(() => import("./views/personal/Settings"));
const TrendPlotGeneral = React.lazy(() => import("./views/Reports/TrendPlotGeneral"));
const Invoice = React.lazy(() => import("./views/Invoice"));
const VOTMInvoice = React.lazy(() => import("./views/VOTMInvoice"));
const CustomerLeadReport = React.lazy(() => import("./views/Reports/CustomerReorderLeads"));
const AvailRubyRewards = React.lazy(() => import("./views/sales/AvailRubyRewards"));
const MobileAppReport = React.lazy(() => import("./views/Reports/MobileAppReport"));
const BonusReport = React.lazy(() => import("./views/Reports/BonusReport"));
const VideoPlayer = React.lazy(() => import("./views/Elearning/components/videoplayer"));

const NewEnrollmentReport = React.lazy(() => import("./views/enrollment/Newenrollment"));
const EnterPcmretailerDetails = React.lazy(() => import("./views/sales/EnterPcmretailerdetails"));

// profile Cards
const IDCard = React.lazy(() => import("./components/IDCard"));
const WelcomeCard = React.lazy(() => import("./components/WelcomeCard"));
const InsuranceCard = React.lazy(() => import("./components/InsuranceCard"));

const TaxInvoiceWebview = React.lazy(() => import("./views/sales/TaxInvoiceWebview"));
const PersonalEnrollmentTree = React.lazy(() => import("./views/Genealogy/PersonalEnrollmentTree"));
const NewGenealogy = React.lazy(() => import("./views/Genealogy/NewGenealogy"));

const RoutesComponent = () => {
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);

  const Protected = ({ isSignedIn, children }) => {
    if (!isSignedIn) {
      WishToaster({
        toastMessage: "You need to login.",
        toastType: "error",
      });
      return <Navigate to="/signin" replace />;
    }
    return children;
  };

  const AuthRoute = ({ isSignedIn, children, redirectPath = "/" }) => {
    return isSignedIn ? <Navigate to={redirectPath} replace /> : children;
  };

  const loadingUI = (
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  const routesData = [
    { path: "/placeorder", component: <PlaceOrder /> },
    { path: "/myorders", component: <MyOrders /> },
    { path: "/orderdetails", component: <OrderDetails /> },
    {
      path: "/enrolluser",
      component: (
        <EnrollmentProvider>
          <EnrollUser />
        </EnrollmentProvider>
      ),
    },
    { path: "/ongoingenrollments", component: <OngoingEnrollments /> },
    { path: "/generatelink", component: <GenerateLink /> },
    { path: "/selectfromgenealogy", component: <SelectFromGeneology isWebview={false} /> },
    { path: "/mygeneologysettings", component: <GenealogySettings /> },
    { path: "/deductionsandadjustments", component: <DeductionsAndAdjustments /> },
    { path: "/commissionreports", component: <CommissionReports /> },
    { path: "/enrollmentreport", component: <NewDistributorReports /> },
    { path: "/co-applicantbonusreport", component: <CoApplicantBonusReport /> },
    { path: "/votmorderreports", component: <ReportGeneral reportID={4} title={"VOTM Order Report"} /> },
    { path: "/storelinkorderreports", component: <ReportGeneral reportID={5} title={"Storelink Order Report"} /> },
    { path: "/orderreports", component: <ReportGeneral reportID={6} title={"Order Report"} /> },
    { path: "/myvoucherreport", component: <ReportGeneral reportID={1} title={"My Voucher Report"} /> },
    { path: "/volumereport", component: <VolumeReport /> },
    { path: "/teamreport", component: <ReportGeneral reportID={3} title={"Team report"} /> },
    { path: "/topperformersreport", component: <ReportGeneralComponent reportID={8} title={"TopPerformers report"} /> },
    { path: "/bonusreport", component: <BonusReport /> },
    { path: "/sponsoreddistributorreport", component: <ReportGeneralComponent reportID={4} title={"Sponsored Distributor report"} /> },
    { path: "/personalenrollmentreport", component: <ReportGeneralComponent reportID={7} title={"Personal Enrollment report"} /> },
    { path: "/storeorderpendingreport", component: <ReportGeneralComponent reportID={10} title={"Storelink Pending Order Report"} /> },
    { path: "/digirockstarreport", component: <ReportGeneralComponent reportID={11} title={"DigiRockstar Achieved Report"} /> },
    { path: "/digirockstarmonthlyreport", component: <ReportGeneralComponent reportID={12} title={"DigiRockstar Achieved Monthly Report"} /> },
    { path: "/productivedistributorreport", component: <ReportGeneralComponent reportID={13} title={"Productive Distributor Report"} /> },
    { path: "/consistentdistributorreport", component: <ReportGeneralComponent reportID={14} title={"Consistent Distributor Report"} /> },
    { path: "/activedistributorreport", component: <ReportGeneralComponent reportID={15} title={"Active Distributor Report"} /> },
    { path: "/firstordertrend", component: <TrendPlotGeneral /> },
    { path: "/reordertrend", component: <TrendPlotGeneral /> },
    { path: "/ranktrend", component: <TrendPlotGeneral /> },
    { path: "/volumetrend", component: <TrendPlotGeneral /> },
    { path: "/myprofile", component: <ProfileDashboard /> },
    { path: "/settings", component: <Settings /> },
    { path: "/paymentsuccess", component: <CongratulationsPage /> },
    { path: "/paymentfailed", component: <FailurePage /> },
    { path: "/notifications", component: <Notifications /> },
    { path: "/votminventory", component: <VotmInventory /> },
    { path: "/mydeliverydetails", component: <VOTMDetailsPage /> },
    {
      path: "/votmconsolidatedorderdetails",
      component: <VOTMConsolidatedOrderDetails />,
    },
    { path: "/downloadorderdetails", component: <Invoice /> },
    { path: "/downloadvotminvoices", component: <VOTMInvoice /> },
    { path: "/downloadcreditnote", component: <CreditNote /> },
    { path: "/customerreorderleads", component: <CustomerLeadReport /> },
    { path: "/redeemrubyrewards", component: <AvailRubyRewards /> },
    { path: "/enterpcmretailerdetails", component: <EnterPcmretailerDetails /> },
    { path: "/mobileappteamcountreport", component: <MobileAppReport /> },
    { path: "/distributoridcard", component: <IDCard /> },
    { path: "/distributorwelcomecard", component: <WelcomeCard /> },
    { path: "/distributorinsurancecard", component: <InsuranceCard /> },
    { path: "/newenrollmentreport", component: <NewEnrollmentReport /> },
    // { path: "/inactiveidswoforeport", component: <InactiveIdWithoutFirstOrderReport reportID={16} title={"Inactive IDs without First Order Report"} /> },
    { path: "/personalenrollmenttree", component: <PersonalEnrollmentTree /> },
    { path: "/newgenealogy", component: <NewGenealogy onEnrollNodeClick={(node) => alert(`Enroll a new node under parent ID: ${node.parentDistId}`)} /> },
    { path: "/enterpcmretailerdetails", component: <EnterPcmretailerDetails /> },
  ];

  const webviews = [
    {
      path: "/taxinvoicewebview",
      component: <TaxInvoiceWebview />,
    },
    {
      path: "/selectfromgenealogy_webview",
      component: <SelectFromGeneology isWebview={true} />,
    },
    {
      path: "/deductionsandadjustments_webview",
      component: <DeductionsAndAdjustments isWebview={true} />,
    },
    {
      path: "/commissionreports_webview",
      component: <CommissionReports isWebview={true} />,
    },
    {
      path: "/enrollmentreport_webview",
      component: <NewDistributorReports isWebview={true} />,
    },
    {
      path: "/co-applicantbonusreport_webview",
      component: <CoApplicantBonusReport isWebview={true} />,
    },
    {
      path: "/volumereport_webview",
      component: <VolumeReport isWebview={true} />,
    },
    {
      path: "/orderreports_webview",
      component: <ReportGeneral reportID={6} title={"Order Report"} isWebview={true} />,
    },
    {
      path: "/votmorderreports_webview",
      component: <ReportGeneral reportID={4} title={"VOTM Order Report"} isWebview={true} />,
    },
    {
      path: "/storelinkorderreports_webview",
      component: <ReportGeneral reportID={5} title={"Storelink Order Report"} isWebview={true} />,
    },
    {
      path: "/myvoucherreport_webview",
      component: <ReportGeneral reportID={1} title={"My Voucher Report"} isWebview={true} />,
    },
    {
      path: "/teamreport_webview",
      component: <ReportGeneral reportID={3} title={"Team Report"} isWebview={true} />,
    },
    {
      path: "/bonusreport_webview",
      component: <ReportGeneralComponent reportID={3} title={"Bonus Report"} isWebview={true} />,
    },
    {
      path: "/sponsoreddistributor_webview",
      component: <ReportGeneralComponent reportID={4} title={"Sponsored Distributor Report"} isWebview={true} />,
    },
    {
      path: "/topperformersreport_webview",
      component: <ReportGeneralComponent reportID={8} title={"TopPerformers Report"} isWebview={true} />,
    },
    {
      path: "/storeorderpendingreport_webview",
      component: <ReportGeneralComponent reportID={10} title={"Store Orders Pending Report"} isWebview={true} />,
    },

    {
      path: "/customerreorderleads_webview",
      component: <CustomerLeadReport reportID={11} title={"Customer Re-order leads"} isWebview={true} />,
    },
    {
      path: "/mobileappteamcountreport_webview",
      component: <MobileAppReport reportID={12} title={"Distributors Yet to Download"} isWebview={true} />,
    },
    {
      path: "/storeorderpendingreport_webview",
      component: <ReportGeneralComponent reportID={13} title={"Storelink Pending Order Report"} isWebview={true} />,
    },
  ];

  return (
    <Suspense fallback={loadingUI}>
      <Routes>
        <Route path="/*" element={<Error404 />} />
        <Route
          path="/signin"
          element={
            <AuthRoute isSignedIn={isSignedIn}>
              <SignIn setisAutheticated={setIsSignedIn} />
            </AuthRoute>
          }
        />
        {/* <Route path="*" element={<Navigate to={isSignedIn ? "/" : "/signin"} replace />} /> */}

        <Route
          path="/"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path="/sales"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Sales />
            </Protected>
          }
        />
        <Route
          path="/storelinkorders"
          element={
            <Protected isSignedIn={isSignedIn}>
              <StorelinkOrders />
            </Protected>
          }
        />
        <Route
          path="/enrollment"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Enrollment />
            </Protected>
          }
        />
        <Route
          path="/mygeneology"
          element={
            <Protected isSignedIn={isSignedIn}>
              <MyGeneology isWebview={false} />
            </Protected>
          }
        />
        <Route path="/mygeneology_webview" element={<MyGeneology isWebview={true} />} />
        <Route
          path="/analysis"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Analysis />
            </Protected>
          }
        />
        <Route
          path="/reportsandtrends"
          element={
            <Protected isSignedIn={isSignedIn}>
              <ReportsDash />
            </Protected>
          }
        />
        <Route
          path="/announcements"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Announcements />
            </Protected>
          }
        />
        <Route
          path="/vmail"
          element={
            <Protected isSignedIn={isSignedIn}>
              <VmailNew />
            </Protected>
          }
        />
        <Route
          path="/e-learning"
          element={
            <Protected isSignedIn={isSignedIn}>
              <Elearning />
            </Protected>
          }
        />
        <Route
          path="/e-learning-video"
          element={
            <Protected isSignedIn={isSignedIn}>
              <VideoPlayer />
              {/* <VideoPlayer /> */}
            </Protected>
          }
        />

        {webviews.map((item, index) => (
          <Route key={index} path={item.path} element={item.component} />
        ))}
        {routesData.map((item, index) => (
          <Route key={index} path={item.path} element={<Protected isSignedIn={isSignedIn}>{item.component}</Protected>} />
        ))}
      </Routes>
    </Suspense>
  );
};

export default RoutesComponent;
