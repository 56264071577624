import { useEffect } from "react";
import _ from "lodash";
import { TreeUtils } from "./TreeUtils";
import { useState } from "react";
import useMasters from "./useMasters";
import useAPIs from "./useAPIs";
import WishToaster from "../components/WishToaster";

export const treeNode = {
  id: String,
  distId: String,
  name: String,
  relation: (String, ""),
  relationId: (Number, 0),
  activityStatus: String,
  achievedRankId: String,
  isActionNode: (Boolean, false),
  isRoot: (Boolean, false),
  isSelectable: (Boolean, false),
  isSelected: (Boolean, false),
  nodes: [],
  parentDistId: String,
  hide: (Boolean, false),
  rankBadge: (String, "Black Diamond Ambassador.png"),
  expanded: (Boolean, false),
  selected: (Boolean, false),
  isBerthEnabled: (Boolean, false),
  image: String,
  position: (Number, 0),
  gv: [],
  pwGv: [],
  resetWeekId: (Number, 0),
  cfl: (Number, 0),
  cfr: (Number, 0),
};

export const treeModel = {
  root: treeNode,
};

export const actionNode = {
  id: "action_node",
  relationId: 0,
  image: "",
  distId: "",
  name: "Enroll",
  relation: "",
  activityStatus: "-1",
  achievedRankId: "-1",
  isActionNode: true,
  isRoot: false,
  isSelectable: false,
  isSelected: false,
  parentDistId: String,
  hide: false,
  rankBadge: "AddNode.png",
  expanded: false,
  selected: false,
  isBerthEnabled: false,
  position: 0,
};

const useGenealogy = (loggedInUserId) => {
  const { apiError, getData, postData } = useAPIs();
  const [treeData, setTreeData] = useState(null);
  const [genealogyData, setGenealogyData] = useState(null);
  const [genealogyError, setError] = useState(null);
  const { ranksList } = useMasters();
  const [ranks, setRanks] = useState(null);
  const [pendingEnrolleesList, setPendingEnrolleesList] = useState(null);
  const [placementPositions, setPlacementPositions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [genealogyLoading, setGenealogyLoading] = useState(false);

  const [distributorStats, setDistributorStats] = useState(null);
  const [distributorGVStats, setDistributorGVStats] = useState(null);
  const [distributorMemberStats, setDistributorMemberStats] = useState(null);

  useEffect(() => {
    setLoading(false);
    setError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (loggedInUserId) {
      setLoading(true);
      // fetchDistributorDetails(loggedInUserId.distributor_id);
    }
  }, [loggedInUserId]);

  useEffect(() => {
    if (ranksList) setRanks(ranksList);
  }, [ranksList]);

  useEffect(() => {
    if (genealogyData) {
      processGenealogyData(genealogyData); // Directly pass genealogyData
    }
  }, [genealogyData]);

  useEffect(() => {
    if (genealogyError) setLoading(false);
  }, [genealogyError]);

  const fetchDistributorDetails = (selectedDistributorId) => {
    setDistributorGVStats(null);
    setDistributorMemberStats(null);
    setDistributorStats(null);
    //alert(selectedDistributorId);
    postData(
      "/reports/fetch-geneology-details-activity-details",
      {
        distributor_id: selectedDistributorId,
      },
      (stats) => {
        //resetError();
        setDistributorStats(stats);
      }
    );

    postData(
      "/reports/fetch-geneology-details-team-count",
      {
        distributor_id: selectedDistributorId,
      },
      (stats) => {
        //resetError();
        setDistributorMemberStats(stats);
      }
    );

    postData(
      "/reports/fetch-geneology-details-group-volume",
      {
        distributor_id: selectedDistributorId,
      },
      (stats) => {
        //resetError();
        setDistributorGVStats(stats);
      }
    );
  };

  const getTreeData = (payload, direction = null, check = false) => {
    setLoading(true);
    payload.distributor_id = parseInt(payload.distributor_id);
    setGenealogyLoading(true);
    setGenealogyData(null);
    postData(
      direction ? `/enrollment/${direction}` : "/enrollment/load-geneology-redis",
      payload,
      (apiData) => {
        const receivedData = Array.from(apiData);
        setGenealogyData(receivedData);
        setGenealogyLoading(false);
        !check && fetchDistributorDetails(receivedData[0].d2.properties.distId);
      },
      (error) => {
        setGenealogyLoading(false);
      }
    );
  };

  const processGenealogyData = (treeNodes = []) => {
    if (treeNodes.length > 0) {
      const processedNodes = [];
      treeNodes.map((node, index) => {
        console.log(treeNodes[index], "test conosle");
        const currentNodeProperties = treeNodes[index].d2.properties;
        const currentNodeNodes = treeNodes[index].path.nodes;
        const currentNodeEdges = treeNodes[index].path.edges;

        if (currentNodeProperties) {
          const { distId, name, activityStatus, achievedRankId, gv, pwGv, resetWeekId, cfl, cfr, isMobileAppDownloaded, is_quiz_attended, score } = currentNodeProperties;
          const foundRank = ranks?.filter((x) => parseInt(x.id) === parseInt(achievedRankId))[0] ?? "Independent Direct Seller";
          const rankBadgeImage = foundRank.title + ".png";

          const newNode = _.clone(treeNode);
          newNode.isBerthEnabled = Boolean(currentNodeProperties.isBerthEnabled);
          newNode.id = distId;
          newNode.distId = distId;
          newNode.name = name;
          newNode.resetWeekId = resetWeekId;
          newNode.activityStatus = activityStatus;
          newNode.achievedRankId = achievedRankId;
          newNode.rankBadge = rankBadgeImage;
          newNode.isRoot = currentNodeEdges.length === 0;
          const edgeToConsider = currentNodeEdges.length > 0 ? currentNodeEdges[currentNodeEdges.length - 1] : null;
          newNode.isMobileAppDownloaded = isMobileAppDownloaded;
          newNode.is_quiz_attended = is_quiz_attended;
          newNode.quiz_score = score;
          newNode.relation = edgeToConsider ? edgeToConsider.relationshipType : "";
          newNode.relationId = edgeToConsider ? edgeToConsider.properties.relation_id : 0;

          newNode.position = 0;
          newNode.gv = gv;
          newNode.pwGv = pwGv;
          newNode.cfl = cfl;
          newNode.cfr = cfr;
          if (currentNodeEdges.length > 0) {
            newNode.parentDistId = currentNodeNodes[currentNodeNodes.length - 2].properties.distId;
          } else {
            newNode.parentDistId = null;
          }

          processedNodes.push(newNode);
        } else {
          console.log("No properties found");
        }
      });
      console.log(processedNodes, "processedNodes");
      setTreeData(TreeUtils.prepareTree(processedNodes, loggedInUserId));
    }
  };

  const getPendingEnrolleesFor = (distributorId, section_level, placement_distributor_id, onSuccess) => {
    setLoading(true);
    setPendingEnrolleesList(null);
    setPlacementPositions(null);
    postData(
      "/enrollment/fetch-pending-enrollee-list",
      {
        distributor_id: distributorId,
        section_level: section_level,
      },
      (enrolleesList) => {
        postData(
          "/enrollment/fetch-available-position",
          {
            placement_distributor_id: placement_distributor_id,
          },
          (positionsList) => {
            //resetError();
            setPlacementPositions(Array.from(positionsList.valid_position_ist));
            onSuccess(enrolleesList, positionsList);
            setPendingEnrolleesList(enrolleesList);
          }
        );
        //resetError();
      }
    );
  };

  const enrollDistributor = (enrollmentDetails, selectedDistributor) => {
    setLoading(true);
    postData("/enrollment/create-new-distributor", enrollmentDetails, () => {
      //resetError();
      getTreeData({
        distributor_id: selectedDistributor,
        logged_in_distributor_id: JSON.parse(localStorage.getItem("distributor")).distributor_id,
        depth: 2,
      });
    });
  };

  const navigateTreeTo = (direction, selectedDistributor, logged_in_distributor) => {
    getTreeData(
      {
        distributor_id: selectedDistributor,
        logged_in_distributor_id: logged_in_distributor,
        depth: 2,
      },
      direction
    );
  };

  const navigateTreeToOneLevelUp = (rootDistributor, logged_in_distributor) => {
    getTreeData(
      {
        root_distributor_id: rootDistributor,
        logged_in_distributor_id: logged_in_distributor,
        depth: 2,
      },
      "load-one-level-up"
    );
  };
  const getPETTreeData = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/load-pet-falkor`,
      payload,
      (response) => {
        onSuccess && onSuccess(response);
        return response;
      },
      (error) => {
        onError && onError(error);
        WishToaster({ toastMessage: error ? error : "Something went wrong!", toastType: "error" });
      }
    );
  };
  const getGenealogyData = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/load-geneology-redis`,
      payload,
      (response) => {
        onSuccess && onSuccess(response);
      },
      (error) => {
        onError && onError(error);
        WishToaster({ toastMessage: error ? error : "Something went wrong!", toastType: "error" });
      }
    );
  };
  const getGenealogyActivityDetails = (payload, onSuccess, onError) => {
    postData(
      `/reports/fetch-geneology-details-activity-details`,
      payload,
      (response) => {
        onSuccess && onSuccess(response);
      },
      (error) => {
        onError && onError(error);
        WishToaster({ toastMessage: error ? error : "Something went wrong!", toastType: "error" });
      }
    );
  };
  const getGenealogyTeamCount = (payload, onSuccess, onError) => {
    postData(
      `/reports/fetch-geneology-details-team-count`,
      payload,
      (response) => {
        onSuccess && onSuccess(response);
      },
      (error) => {
        onError && onError(error);
        WishToaster({ toastMessage: error ? error : "Something went wrong!", toastType: "error" });
      }
    );
  };
  const getGenealogyGroupVolume = (payload, onSuccess, onError) => {
    postData(
      `/reports/fetch-geneology-details-group-volume`,
      payload,
      (response) => {
        onSuccess && onSuccess(response);
      },
      (error) => {
        onError && onError(error);
        WishToaster({ toastMessage: error ? error : "Something went wrong!", toastType: "error" });
      }
    );
  };
  const getPendingEnrolleeList = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/fetch-pending-enrollee-list`,
      payload,
      (response) => {
        onSuccess && onSuccess(response);
      },
      (error) => {
        onError && onError(error);
        WishToaster({ toastMessage: error ? error : "Something went wrong!", toastType: "error" });
      }
    );
  };
  const getAvailablePositions = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/fetch-available-position`,
      payload,
      (response) => {
        onSuccess && onSuccess(response);
      },
      (error) => {
        onError && onError(error);
        WishToaster({ toastMessage: error ? error : "Something went wrong!", toastType: "error" });
      }
    );
  };
  return {
    distributorGVStats,
    distributorMemberStats,
    distributorStats,
    enrollDistributor,
    genealogyError,
    getPendingEnrolleesFor,
    getTreeData,
    loading,
    genealogyLoading,
    navigateTreeTo,
    navigateTreeToOneLevelUp,
    pendingEnrolleesList,
    placementPositions,
    ranks,
    treeData,
    fetchDistributorDetails,
    getPETTreeData,
    getGenealogyData,
    getGenealogyActivityDetails,
    getGenealogyTeamCount,
    getGenealogyGroupVolume,
    getPendingEnrolleeList,
    getAvailablePositions,
  };
};

export default useGenealogy;
