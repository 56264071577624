import React from "react";
import moment from "moment";
import { Doughnut } from "react-chartjs-2";
import WishSimpleCard from "../../components/WishSimpleCard";
import { useNavigate } from "react-router-dom";

export default function TeamMemberStatus({ distributorMemberStats, data, className, width, height, loading }) {
  const navigate = useNavigate();

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      // Assuming you have labels associated with each section
      const clickedLabel = data.labels[elements[0].index];
      const clickedData = data.datasets[0].data[elements[0].index];

      let selectedData = [];
      if (clickedLabel === "Active Members") {
        selectedData = [1];
      }
      if (clickedLabel === "Inactive Members") {
        selectedData = [2];
      } else {
        selectedData = [3, 4, 5, 6, 7, 8, 9];
      }
      navigate("/teamreport", { state: { clickedData: clickedData, clickedLabel: clickedLabel, selectedData: selectedData } });
    }
  };

  const options = {
    onClick: handleChartClick,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const plugin = {
    id: "custom_text",
    beforeDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();
      ctx.font = "22px Inter";
      ctx.fillStyle = "grey";

      var line1 = distributorMemberStats?.Active ?? 0,
        line1X = Math.round((chart.width - ctx.measureText(line1).width) / 2),
        line1Y = chart.height / 2 - 25;
      ctx.fillText(line1, line1X, line1Y + 2);

      ctx.font = "14px Inter";
      var line2 = "Active members",
        line2X = Math.round((chart.width - ctx.measureText(line2).width) / 2),
        line2Y = chart.height / 2;
      ctx.fillText(line2, line2X, line2Y + 2);

      ctx.font = "14px Inter";
      var line3 = "of " + (parseInt(distributorMemberStats?.Active) + parseInt(distributorMemberStats?.Inactive) + parseInt(distributorMemberStats?.Others)),
        line3X = Math.round((chart.width - ctx.measureText(line3).width) / 2),
        line3Y = chart.height / 2 + 25;
      ctx.fillText(line3, line3X, line3Y + 2);
      ctx.restore();
    },
  };

  return (
    <WishSimpleCard className={className} changeBorder={false} header={<h5>Team Member Status as on {moment().format("DD-MMM-YY")}</h5>} cardBodyClassName="pt-0 wish-speedometer">
      <div className="text-center">
        <div
          style={{
            height: height,
            width: width,
          }}
        >
          {!loading ? (
            <Doughnut
              id="dgTeamMemberStatus"
              data={data}
              options={options}
              plugins={distributorMemberStats && distributorMemberStats?.Active ? [plugin] : []}
              //ref={doughnutRef}
            />
          ) : (
            <div className="card skeleton-circle"></div>
          )}
        </div>
      </div>
      <div className="pt-2">
        <table width="100%">
          <thead>
            <tr>
              <th className="text-center">{distributorMemberStats?.Active}</th>
              <th className="text-center"> {distributorMemberStats?.Inactive}</th>
              <th className="text-center"> {distributorMemberStats?.Others}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center text-white" style={{ backgroundColor: "#87bcf1" }}>
                Active
              </td>
              <td className="text-center text-white" style={{ backgroundColor: "#fbe892" }}>
                Inactive
              </td>
              <td className="text-center text-white" style={{ backgroundColor: "#f893b0" }}>
                Others
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </WishSimpleCard>
  );
}
